<template>
    <a-card :bordered="false">
      <div slot="extra" v-if="viewTab">
        <a-radio-group v-model="menuSelect" :defaultValue="menuSelect">
          <a-radio-button
            v-for="item in menuList"
            :value="item.id"
            :key="item.id"
            @click="menuChoose(item.description)"
            style="margin:0 10px 10px 0"
            >{{ item.description }}</a-radio-button>
        </a-radio-group>
      </div>
      <a-button type="primary" style="margin-bottom:20px;" @click="plusMenu"><a-icon type="plus"/> 添加</a-button>
      <a-button
        style="margin-bottom:20px; margin-left:20px;"
        @click="showTab(true)"
        v-if="!viewTab"
      ><a-icon type="down" /> 展开分类</a-button>
      <a-button
        style="margin-bottom:20px; margin-left:20px;"
        @click="showTab(false)"
        v-if="viewTab"
      ><a-icon type="up" /> 收起分类</a-button>
      <a-table
        :columns="column"
        :dataSource="menuDetailList"
        :pagination="pagination"
        rowKey="id"
        @change="handleTableChange"
      >
        <template slot="menu" slot-scope="text">
          <template>{{ text }}</template>
        </template>
        <template v-for="col in ['description', 'value']" :slot="col" slot-scope="text, record, index">
          <a-input
            :key="col"
            v-if="record.editable"
            style="margin: -5px 0"
            :value="text"
            @change="e => tableChange(e.target.value, col, index)"
          />
          <template v-else>{{ text }}</template>
        </template>
        <template slot="action" slot-scope="text, record, index">
          <a @click="editItem(index)" v-if="!record.editable">编辑</a>
          <a @click="updateItem(index)" v-if="record.editable" style="color:#ff3300; font-weight:bold">保存</a>
          <a-divider type="vertical" />
          <a @click="delItem(record.id, record.menu)">删除</a>
        </template>
      </a-table>
    </a-card>
</template>

<script>
import { getMenuList, updataMenu, deleteMenu } from '@/api/basic/common'

export default {
  name: 'TableList',
  components: {
  },
  data () {
    return {
      pagination: {
        total: 0,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ['10', '15', '20'],
        showTotal: total => `共有 ${total} 条数据`
      },
      nowPage: 1,
      viewTab: true,
      // create model
      visible: false,
      confirmLoading: false,
      mdl: null,
      // 高级搜索 展开/关闭
      advanced: false,
      // 查询参数
      menuParam: {
        menu: '零级菜单'
      },
      // 接口返回数据
      menuList: [],
      menuSelect: '1',
      menuDetailList: [],
      // table
      column: [
        {
          title: '#',
          dataIndex: 'index',
          key: 'index',
          width: '10%'
        },
        {
          title: '菜单名称',
          dataIndex: 'description',
          key: 'description',
          width: '25%',
          scopedSlots: { customRender: 'description' }
        },
        {
          title: '分类',
          dataIndex: 'menu',
          key: 'menu',
          width: '25%',
          scopedSlots: { customRender: 'menu' }
        },
        {
          title: '菜单值',
          dataIndex: 'value',
          key: 'value',
          width: '20%',
          scopedSlots: { customRender: 'value' }
        },
        {
          title: '操作',
          dataIndex: 'action',
          key: 'action',
          width: '20%',
          scopedSlots: { customRender: 'action' }
        }
      ]
    }
  },
  filters: {},
  created () {
    this.getMenu(this.menuParam)
  },
  computed: {},
  methods: {
    // 接口请求
    getMenu (p) {
      getMenuList(p).then(response => {
        this.menuList = response.data.list
        this.menuSelect = response.data.list[0].id
        var param = {
          menu: response.data.list[0].description
        }
        this.detailMenu(param)
      })
    },
    detailMenu (p) {
      getMenuList(p).then(response => {

        for (var i = 0;i<response.data.list.length;i++) {
          response.data.list[i].index = i + 1
        }
        this.menuDetailList = response.data.list
      })
    },
    // 操作方法
    plusMenu () {
      this.$router.push({
        path: '/base/menu/edit'
      })
    },
    menuChoose (v) {
      var param = {
        menu: v
      }
      this.detailMenu(param)
    },
    editItem (index) {
      var list = this.menuDetailList
      list[index + (this.nowPage - 1) * this.pagination.pageSize].editable = true
      this.menuDetailList = []
      this.menuDetailList = list
      // console.log(this.menuDetailList[index])
    },
    updateItem (index) {
      updataMenu(this.menuDetailList[index + (this.nowPage - 1) * this.pagination.pageSize]).then(response => {
        var list = this.menuDetailList
        list[index].editable = false
        this.menuDetailList = []
        this.menuDetailList = list
        this.$message.info('保存成功')
      })
    },
    delItem (id, desc) {
      var self = this
      this.$confirm({
        title: '确认删除',
        content: `是否确认删除当前菜单项？`,
        cancelText: '取消',
        okText: '确定',
        okType: 'danger',
        onOk () {
          return new Promise((resolve, reject) => {
            deleteMenu({ id: id }).then(response => {
              self.$message.error('删除成功')
              var param = {
                menu: desc
              }
              self.detailMenu(param)
            }).catch((e) => {
              // self.$message.error('删除成功')
            }).finally(() => {
              resolve()
            })
          })
        }
      })
    },
    tableChange (value, col, i) {
      this.menuDetailList[i + (this.nowPage - 1) * this.pagination.pageSize][col] = value
    },
    handleTableChange (pagination) {
      // console.log(pagination)
      this.nowPage = pagination.current
    },
    showTab (v) {
      this.viewTab = v
    }
  }
}
</script>
